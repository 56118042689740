import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/olyplayer',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'jw',
  //   loadChildren: '@oly/playerdemo/jw#PlayerdemoJwModule'
  // },
  {
    path: '',
    loadChildren: () => import('@oly/playerdemo/olyplayer').then(m => m.PlayerdemoOlyplayerModule),
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
